
import { Component } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Calendar from "@/components/Calender.vue";
import ReservationModule from "@/store/reservation";
import HospitalModule from "@/store/hospital";
import FacilityService from "@/services/facilities";
import CheckCommonLogin from "@/utils/CheckCommonLogin";
import { InformationData } from "@/@types/information";
import moment from "moment";

@Component({
  name: "DateSelect",
  components: {
    Header,
    Footer,
    Calendar,
  },
})
export default class DateSelect extends CheckCommonLogin {
  private get selectedCourse() {
    return ReservationModule.selectedCourse;
  }

  private get hospitalId() {
    return HospitalModule.hospitalId;
  }

  private hospitalData = null;

  private noticeList: InformationData[] = [];

  private noticeDateFactory(time: string) {
    return moment(new Date(time).toISOString()).format("YYYY/MM/DD");
  }

  private mounted() {
    this.getHospitalData();
    this.getThreeLatestNotice();
  }

  private async getHospitalData() {
    const res = await FacilityService.getHospital(this.hospitalId);
    this.hospitalData = res.data.data[0];
  }

  private async getThreeLatestNotice() {
    const res = await FacilityService.getHospitalNoticeList(this.hospitalId);
    const now = moment(new Date().toISOString());
    const filteredPublicNoticeList: InformationData[] = res.data.data.filter((notice: InformationData) => {
      const startDate = moment(new Date(notice.start_datetime).toISOString());
      const endDate = notice.end_datetime ? moment(new Date(notice.end_datetime).toISOString()) : null;
      if (endDate) {
        return now.isAfter(startDate) && now.isBefore(endDate);
      } else {
        return now.isAfter(startDate);
      }
    });
    // 降順にソート
    filteredPublicNoticeList.sort(function (x, y) {
      var firstDate = new Date(x.start_datetime),
        SecondDate = new Date(y.start_datetime);

      if (firstDate < SecondDate) return -1;
      if (firstDate > SecondDate) return 1;
      return 0;
    });
    this.noticeList = filteredPublicNoticeList.slice(0, 3);
  }
}
