
import { Component, Vue, Ref, Watch } from "vue-property-decorator";
import "@fullcalendar/core/vdom"; // solves problem with Vite

import moment, { Moment } from "moment";
import { BusinessHours, WeeklyEvent } from "@/@types/event";
import CalendarModule from "@/store/calendar";
import ReservationModule from "../store/reservation";
import HospitalModule from "../store/hospital";
import MemberService from "@/services/members";
import { CourseDay, CourseTime, PossibleReservation, ReservationData } from "@/@types/reservation";
import japaneseHolidays from "japanese-holidays";

@Component
export default class Home extends Vue {
  private get currentEvents() {
    return CalendarModule.currentEvents;
  }

  private get selectedCourse() {
    return ReservationModule.selectedCourse;
  }
  private get selectedNumberOfPets() {
    return ReservationModule.selectedNumberOfPets;
  }
  private get businessHours() {
    return CalendarModule.businessHours;
  }
  private get currentDate() {
    return CalendarModule.currentDate;
  }

  private get hospitalId() {
    return HospitalModule.hospitalId;
  }

  private get numberOfPetsSelectItems() {
    const array = [];
    if (this.selectedCourse) {
      for (let i = 1; i <= this.selectedCourse?.available_count; i++) {
        array.push(i);
      }
    }
    return array;
  }

  private get isPrevButtonDisabled() {
    return this.isCalendarViewToday();
  }

  private onPrevButtonClick() {
    this.initialDate = moment(this.initialDate).add(-7, "days").toDate();
    this.getPossibleReservations();
  }

  private onNextButtonClick() {
    this.initialDate = moment(this.initialDate).add(7, "days").toDate();
    this.getPossibleReservations();
  }

  // 1週間後の日付がpossibleReservationsにない場合trueを返す
  private isNextButtonDisabled() {
    const endDate = moment(this.initialDate).add(7, "days");
    return !this.possibleReservations.some((reservation) => {
      return moment(reservation.datetime).isSameOrAfter(moment(endDate));
    });
  }

  //カレンダーのmax-dateを設定
  private get maxDate() {
    let maxDate: Date | null = null;
    let startReceptionMonth = 0;
    let startReceptionDate = 0;
    this.selectedCourse?.details.forEach((detail) => {
      const { end_year, end_month, end_date } = detail.period;
      if (end_year && end_month && end_date) {
        const detailDate = new Date(end_year, end_month - 1, end_date);
        if (detailDate > maxDate && new Date() < detailDate) {
          maxDate = detailDate;
        }
      } else {
        maxDate = null;
        detail.days.forEach((day) => {
          day.times.forEach((time) => {
            if (startReceptionMonth < time.reception_time.start_month) {
              startReceptionMonth = time.reception_time.start_month;
            }
            if (startReceptionDate < time.reception_time.start_date) {
              startReceptionDate = time.reception_time.start_date;
            }
          });
        });
      }
    });
    return (
      maxDate ||
      new Date(new Date().setMonth(new Date().getMonth() + startReceptionMonth)).setDate(
        new Date().getDate() + startReceptionDate
      )
    );
  }

  private get minDate() {
    // detail.periodのstart_year, start_month, start_dateを比較して一番直近のものをDateで返す
    if (this.selectedCourse?.details) {
      let minDate: Date | null = null;
      this.selectedCourse.details.forEach((detail) => {
        const { start_year, start_month, start_date } = detail.period;
        const detailDate = new Date(start_year, start_month - 1, start_date);
        if (!minDate || detailDate < minDate) {
          minDate = detailDate;
        }
      });
      // minDateが現在より過去だったら現在の日付を返す
      return minDate && minDate < new Date() ? new Date() : minDate;
    }
    return new Date();
  }

  private loading = false;

  private possibleReservations: PossibleReservation[] = [];

  private initialDate = new Date();

  private viewData: any = [];

  private changeSelectedNumberOfPets(number: number) {
    ReservationModule.changeSelectedNumberOfPets(number);
    location.reload();
  }

  private createWeeklySchedule(possibleReservations: any) {
    const endDate = new Date(this.initialDate);
    endDate.setDate(endDate.getDate() + 7);

    // 1. this.initialDateから1週間分のデータに絞り込み
    const weeklyReservations = possibleReservations.filter((reservation: ReservationData) => {
      const reservationDate = moment(reservation.datetime);
      const initialDate = moment(this.initialDate)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .set("millisecond", 0);
      return reservationDate.isSameOrAfter(initialDate) && reservationDate.isBefore(moment(endDate));
    });

    // 2. 絞り込んだデータからhh:mmの時間を抽出して配列を作る
    const times = weeklyReservations.map((reservation: ReservationData) => {
      return new Date(reservation.datetime).toTimeString().substring(0, 5);
    });

    // 3. 時間配列の中からダブりを削除し時間が早い順に並び替える
    const uniqueTimes = [...new Set(times)].sort();
    // 4. テーブル構造になるよう整形する[[時間,予約枠, 予約枠...],[時間,予約枠, 予約枠...]]
    const formattedData = uniqueTimes.map((time) => {
      const dailyData = new Array(7).fill(null).map((_, index) => {
        const day = new Date(this.initialDate);
        if (index !== 0) {
          day.setDate(day.getDate() + index);
        }
        const dayStr = day
          .toLocaleDateString("ja-JP", { year: "numeric", month: "2-digit", day: "2-digit" })
          .split("/")
          .join("-");
        const reservationForDayAndTime = weeklyReservations.find((reservation: ReservationData) => {
          const reservationDate = new Date(reservation.datetime)
            .toLocaleDateString("ja-JP", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .split("/")
            .join("-");
          const reservationTime = new Date(reservation.datetime).toTimeString().substring(0, 5);
          return reservationDate == dayStr && reservationTime == time;
        });
        return reservationForDayAndTime
          ? {
              reserved_count: reservationForDayAndTime.reserved_count,
              remained_count: reservationForDayAndTime.remained_count,
              available_count: reservationForDayAndTime.available_count,
              datetime: reservationForDayAndTime.datetime,
            }
          : { reserved_count: 0, remained_count: 0, available_count: 0 };
      });
      return [time, ...dailyData];
    });
    //4/16 (火)の形式で日付の配列を作る["4/16 (火)", "4/17 (水)""]
    const days = new Array(8).fill(null).map((_, index) => {
      if (index === 0) return "";
      const day = new Date(this.initialDate);
      day.setDate(day.getDate() + (index - 1));
      return `${day.getMonth() + 1}/${day.getDate()} (${["日", "月", "火", "水", "木", "金", "土"][day.getDay()]})`;
    });

    this.viewData = [days, ...formattedData];
  }

  @Watch("currentDate")
  private async getPossibleReservations() {
    try {
      this.loading = true;
      const calendarStartDate = moment(this.initialDate);
      const calendarEndDate = moment(this.initialDate).add(7, "days");
      const startYear = calendarStartDate.get("year").toString();
      const startMonth = String(calendarStartDate.get("month") + 1);
      const endYear = calendarEndDate.get("year").toString();
      const endMonth = String(calendarEndDate.add(7, "days").get("month") + 1);
      if (this.selectedCourse && this.selectedCourse.id) {
        const res = await MemberService.getPossibleReservations(
          this.hospitalId,
          this.selectedCourse.id,
          startYear,
          startMonth
        );
        this.possibleReservations = res.data.data;
        if (endMonth !== startMonth) {
          const res = await MemberService.getPossibleReservations(
            this.hospitalId,
            this.selectedCourse.id,
            endYear,
            endMonth
          );
          this.possibleReservations = [...this.possibleReservations, ...res.data.data];
        }
        this.createWeeklySchedule(this.possibleReservations);
      }
      this.loading = false;
    } catch (error: any) {
      this.loading = false;
      throw new Error(error);
    }
  }

  private selectDate(start: string) {
    if (this.$route.name === "MyPageReservationEdit") {
      ReservationModule.changeSelectedDate(start);
    } else {
      ReservationModule.changeSelectedDate(start);
      this.$router.push({ name: "HospitalReservation" });
    }
  }
  private start = ""; //現在表示している日時のスタート
  private end = ""; //現在表示している日時のエンド

  private isCalendarViewToday() {
    const today = moment(new Date()).format("YYYY/MM/DD");
    const calendarViewDay = this.initialDate ? moment(this.initialDate).format("YYYY/MM/DD") : "";
    return today == calendarViewDay;
  }

  private changePrevButtonDisabled(element: Element) {
    if (this.isCalendarViewToday()) {
      element.setAttribute("disabled", "true");
    } else {
      element.removeAttribute("disabled");
    }
  }

  private changeInitialDate() {
    const date = moment(this.initialDate);
    // 時間を00:00にセット
    date.set("hour", 0);
    date.set("minute", 0);
    date.set("second", 0);
    this.initialDate = date.toDate();
    CalendarModule.changeCurrentDate(moment(this.initialDate).format("YYYY-MM-DD"));
    if (this.currentDate === "Invalid date") {
      CalendarModule.changeCurrentDate("");
    }
  }

  private checkHoliday(date: Date) {
    return japaneseHolidays.isHoliday(date);
  }

  private async mounted() {
    const prevButton = document.getElementsByClassName("prev-button")[0];
    const nextButton = document.getElementsByClassName("next-button")[0];
    prevButton.addEventListener("click", () => {
      this.getPossibleReservations();
      this.changePrevButtonDisabled(prevButton);
    });
    nextButton.addEventListener("click", () => {
      this.getPossibleReservations();
      this.changePrevButtonDisabled(prevButton);
    });
    this.changePrevButtonDisabled(prevButton);

    this.getPossibleReservations();
    CalendarModule.changeCurrentDate("");
  }
  private convertDays(days: string[]) {
    const convertedDays = days.map((value) => {
      if (value === "Sun") {
        return 0;
      } else if (value === "Mon") {
        return 1;
      } else if (value === "Tue") {
        return 2;
      } else if (value === "Wed") {
        return 3;
      } else if (value === "Thu") {
        return 4;
      } else if (value === "Fri") {
        return 5;
      } else if (value === "Sat") {
        return 6;
      } else {
        return 99;
      }
    });
    return convertedDays;
  }
  private businessHourStringFactory(hour: number, minute: number, duration?: number) {
    let hours = moment().set("hour", hour).set("minute", minute).format("HH:mm");
    if (duration && hour === 0 && minute === 0) {
      hours = moment().set("hour", hour).set("minute", minute).add(-1, "minute").format("HH:mm");
    } else if (duration) {
      hours = moment().set("hour", hour).set("minute", minute).add(duration, "minute").format("HH:mm");
      if (hours.slice(0, 2) === "00" && hours.slice(3, 5) === "00") {
        hours = "23:59";
      }
    }
    return hours;
  }
  private endBusinessHourStringFactory(start_time: string, end_time: string) {
    const duration = this.selectedCourse?.duration_minute;
    if (!duration) {
      throw new Error("Duration is not defined");
    }

    // start_time = "09:15"
    // end_time = "17:00"
    // duration = 90
    // start_timeにdurationを足していき、end_timeを超えた時、その1つ前の時間を返す

    const [startHour, startMinute] = start_time.split(":").map(Number);
    const [endHour, endMinute] = end_time.split(":").map(Number);

    let startTime = moment().set("hour", startHour).set("minute", startMinute);
    const endTime = moment().set("hour", endHour).set("minute", endMinute);

    let previousTime = startTime.clone(); // 1つ前の時間を保存

    // startTimeがendTimeを超えるまでループ
    while (startTime.isSameOrBefore(endTime)) {
      previousTime = startTime.clone();
      startTime = startTime.add(duration, "minutes");
    }

    // 1つ前の時間をHH:MM形式で返す
    return previousTime.format("HH:mm");
  }
  private businessHoursArrayFactory() {
    const businessHoursArray: BusinessHours[] = [];

    this.selectedCourse?.details.forEach((detail) => {
      detail.days?.forEach((courseDay: CourseDay) => {
        // 曜日のリストをstring→numberへ
        const daysOfWeek = this.convertDays(courseDay.available_days);
        // 終日休診じゃない場合
        if (!courseDay.is_no_medical_all_day) {
          courseDay.times.forEach((time) => {
            const startTime = this.businessHourStringFactory(time.on_time.start_hour, time.on_time.start_minute);
            let endTime = this.businessHourStringFactory(time.on_time.end_hour, time.on_time.end_minute);
            endTime = this.endBusinessHourStringFactory(startTime, endTime);
            if (time.on_time.end_hour == 24) {
              endTime = "24:00";
            }
            businessHoursArray.push({
              daysOfWeek,
              startTime,
              endTime,
            });
          });
        }
      });
    });
    CalendarModule.changeBusinessHours(businessHoursArray);
  }

  private getLabelColor(label: string) {
    if (label === "一般診療") {
      return "rgba(85, 171, 53, 0.6)";
    } else if (label === "予防接種") {
      return "rgba(53, 62, 171, 0.6)";
    }
  }

  private weeklyFactory(businessHours: BusinessHours[]) {
    const weekly: WeeklyEvent[] = [];
    const now = new Date();
    let startHour = 0;
    let startMinute = 0;

    //仕事開始時間
    businessHours.sort((a, b) => {
      return Number(a.startTime.slice(0, 2)) < Number(b.startTime.slice(0, 2)) ? -1 : 1;
    });
    businessHours.forEach((businessHour) => {
      let weeklyStart = moment(this.start)
        .hour(Number(businessHour.startTime.slice(0, 2)))
        .minute(Number(businessHour.startTime.slice(3, 5)))
        .format();
      startHour = Number(businessHour.startTime.slice(0, 2));
      startMinute = Number(businessHour.startTime.slice(3, 5));
      // 仕事終了時間
      // businessHours.sort((a: BusinessHours, b: BusinessHours) => {
      //   if (a.endTime.slice(0, 2) > b.endTime.slice(0, 2)) {
      //     return 1;
      //   } else {
      //     return -1;
      //   }
      // });
      let endTime = moment(weeklyStart)
        .hour(Number(businessHour.endTime.slice(0, 2)))
        .minute(Number(businessHour.endTime.slice(3, 5)))
        .format();
      // 予約受付枠のスタート日時定義
      // 同じ曜日設定の時間帯リスト
      // const dayTimes = businessHours.filter(
      //   (value) =>
      //     businessHour.daysOfWeek.length === [...new Set([...value.daysOfWeek, ...businessHour.daysOfWeek])].length
      // );
      // 表示しているカレンダーのスタート日時からエンド日時までループ
      for (let i = 1; moment(weeklyStart).isBefore(moment(this.end)); i++) {
        // 予約数
        const event: WeeklyEvent = {
          title: 0,
          start: weeklyStart,
          end: moment(weeklyStart)
            .add(this.selectedCourse!.duration_minute / 60, "hours")
            .format(),
        };
        const eventDay = moment(event.start).get("day");
        if (
          businessHour.daysOfWeek.includes(eventDay) ||
          (businessHour.daysOfWeek.includes(99) && this.checkHoliday(new Date(event.start)))
        ) {
          //予約可能枠リストから同日時の予約枠取得
          const findPossibleReservation = this.possibleReservations.find((reservation) =>
            moment(reservation.datetime).isSame(weeklyStart)
          );
          if (findPossibleReservation) {
            event.title = findPossibleReservation.remained_count;
          }
          if (this.selectedCourse?.details[0].special_times) {
            const findSpecialTime = this.selectedCourse?.details[0].special_times.find(
              (item) =>
                moment()
                  .set("year", item.year)
                  .set("month", item.month - 1)
                  .set("date", item.date)
                  .set("hour", item.start_hour)
                  .set("minute", item.start_minute)
                  .set("second", 0)
                  .format() === weeklyStart
            );
            if (findSpecialTime && findPossibleReservation) event.title = findPossibleReservation.remained_count;
          }
          if (moment(event.start).isBefore(now)) {
            event.title = 0;
          }
          weekly.push(event);
        }
        if (event.end >= endTime) {
          weeklyStart = moment(weeklyStart).add(1, "days").hour(startHour).minute(startMinute).format();
          endTime = moment(endTime).add(1, "days").format();
        } else {
          weeklyStart = event.end;
        }
      }
    });
    let nonBusinessWeekly: WeeklyEvent[] = [];
    nonBusinessWeekly = this.addNonBusinessHourTime(this.businessHours);
    const filteredEvents = this.filterEvents([...weekly, ...nonBusinessWeekly]);
    // weeklyの中にstartが同じ時間のものがあればtitleが少ない方を削除
    return filteredEvents;
  }

  private addNonBusinessHourTime(businessHours: BusinessHours[]) {
    const nonBusinessWeekly: WeeklyEvent[] = [];
    let startHour = 0;
    let startMinute = 0;

    //仕事開始時間
    businessHours.sort((a, b) => {
      return Number(a.startTime.slice(0, 2)) < Number(b.startTime.slice(0, 2)) ? -1 : 1;
    });
    businessHours.forEach((businessHour) => {
      let weeklyStart = moment(this.start)
        .hour(Number(businessHour.startTime.slice(0, 2)))
        .minute(Number(businessHour.startTime.slice(3, 5)))
        .format();
      startHour = Number(businessHour.startTime.slice(0, 2));
      startMinute = Number(businessHour.startTime.slice(3, 5));
      let endTime = moment(weeklyStart)
        .hour(Number(businessHour.endTime.slice(0, 2)))
        .minute(Number(businessHour.endTime.slice(3, 5)))
        .format();
      // 表示しているカレンダーのスタート日時からエンド日時までループ
      for (let i = 1; moment(weeklyStart).isBefore(moment(this.end)); i++) {
        // 予約数
        const event: WeeklyEvent = {
          title: 0,
          start: weeklyStart,
          end: moment(weeklyStart)
            .add(this.selectedCourse!.duration_minute / 60, "hours")
            .format(),
        };
        // 同じ時間帯に予約枠がなければpush
        if (!this.currentEvents.find((item) => moment(item.start).isSame(event.start))) {
          nonBusinessWeekly.push(event);
        }
        weeklyStart = event.end;
      }
    });
    return nonBusinessWeekly;
  }

  private filterEvents(events: WeeklyEvent[]) {
    const filteredEvents: WeeklyEvent[] = [];
    const groupedEvents: any = {};

    // グループ化された同じ"start"日時のイベントを作成
    events.forEach((event) => {
      const start = event.start;
      if (groupedEvents[start]) {
        groupedEvents[start].push(event);
      } else {
        groupedEvents[start] = [event];
      }
    });

    // 同じ"start"日時のイベントの中で"tile"が一番大きいものを選択
    Object.values(groupedEvents).forEach((group: any) => {
      if (group.length > 1) {
        const maxTitleEvent = group.reduce((maxEvent: WeeklyEvent, currentEvent: WeeklyEvent) => {
          return currentEvent.title > maxEvent.title ? currentEvent : maxEvent;
        });
        filteredEvents.push(maxTitleEvent);
      } else {
        filteredEvents.push(group[0]);
      }
    });

    return filteredEvents;
  }
}
